







import { Component, Prop, Vue } from 'vue-property-decorator'
import LabelBase from '@/components/atoms/LabelBase.vue'
import TitleInnerBox from '@/components/atoms/TitleInnerBox.vue'

@Component({
  components: {
    LabelBase,
    TitleInnerBox,
  },
})
export default class TitleLesson extends Vue {
  @Prop()
  lesson!: {}
}
