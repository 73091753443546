








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DivTitled extends Vue {
  @Prop()
  title?: string
}
