


























import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import TitleLesson from '@/components/organisms/TitleLesson.vue'
import LookingBack from '@/components/molecules/LookingBack.vue'
import RatioBarText from '@/components/atoms/RatioBarText.vue'
import _ from 'lodash'

@Component({
  components: {
    ColoredBox,
    TitleLesson,
    LookingBack,
    RatioBarText,
  },
})
export default class LessonInformation extends Vue {
  @Prop()
  lesson!: {
    curriculums: []
  }

  @Prop()
  whenLesson!: string

  @Prop({ default: false })
  showPoint?: boolean

  @Prop({ default: false })
  showLookingBack?: boolean

  get showBeforeLesson(): boolean {
    return !_.isEmpty(this.lesson) && !_.isEmpty(this.lesson.curriculums)
  }
}
