







import { Component, Prop, Vue } from 'vue-property-decorator'
import StudentFaceIcon from '@/components/atoms/StudentFaceIcon.vue'

@Component({
  components: {
    StudentFaceIcon,
  },
})
export default class LookingBack extends Vue {
  private showStudentReview(): void {
    this.$modal.show('modal-student-review')
  }

  @Prop()
  faceNumber!: number

  @Prop({ default: false })
  hasUnread?: boolean
}
