






import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'

@Component({
  components: {
    ColoredBox,
  },
})
export default class HomeworkInformation extends Vue {
  @Prop()
  homeworks!: string[]
}
