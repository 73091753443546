







import { Component, Prop, Vue } from 'vue-property-decorator'
import DivDeletable from '@/components/molecules/DivDeletable.vue'

@Component({
  components: {
    DivDeletable,
  },
})
export default class Conditions extends Vue {
  @Prop()
  items!: []

  private removeItem(label: string) {
    this.$emit('removeItem', label)
  }
}
