







































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import DivReset from '@/components/atoms/DivReset.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DivTitled from '@/components/molecules/DivTitled.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import { StudentGoal, EntranceChoiceSchool } from '@/models/api/studentGoal'

export type StudentInfoType = {
  id?: number
  nickname?: string
  code?: string
  iconUrl?: string
  school?: string
  grade?: string
  userId?: number
  classModeCode?: string
  status?: { type: string; message: string }[]
  goal?: StudentGoal | EntranceChoiceSchool | null
}

@Component({
  components: {
    ColoredBox,
    ImageRound,
    LabelBase,
    DivReset,
    ButtonBase,
    DivTitled,
  },
})
export default class StudentInformationStatus extends Mixins(ClassModeMethods) {
  // 各授業モードのリンク名
  private readonly HISTORY_TOP = {
    TJ: 'TeacherTargetSituation',
    TK: 'TeacherTestStepSituation',
    KS: 'TeacherSeminarTargetSituation',
    NY: 'TeacherEntrancePretestSituation',
    HJ: 'TeacherAssistMaterialLessonSituation',
  }

  private showStudentReview(): void {
    this.$modal.show('modal-student-review')
  }

  private async onClickHistory() {
    if (!this.student.classModeCode) return
    const pathName = this.HISTORY_TOP[this.student.classModeCode]
    if (!pathName) return
    await this.setCookieStudentInfo()
    this.$router.push({ name: pathName })
  }

  /**
   * 生徒情報をCookieにセットする
   */
  private async setCookieStudentInfo() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie.student = {
      userId: this.student.userId,
      nickname: this.student.nickname,
      studentId: this.student.id,
      studentCode: this.student.code,
      iconUrl: this.student.iconUrl,
      schoolName: this.student.school,
      gradeName: this.student.grade,
    }
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  private get nameWithMode(): string {
    const classModeText = this.classModeText(this.student.classModeCode || 'TJ')
    if (!this.student.goal) return classModeText

    if (this.student.classModeCode == 'NY') {
      // 入試対策モードの場合、student.goalはEntranceChoiceSchoolとして扱う
      return `${classModeText}モード: ${(this.student.goal as EntranceChoiceSchool).comment}`
    }

    return `${classModeText}モード: ${(this.student.goal as StudentGoal).name}`
  }

  @Prop()
  student: StudentInfoType = {}
}
