






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DivDeletable extends Vue {
  @Prop()
  label!: string

  @Prop()
  name!: string
}
