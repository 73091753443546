












import { Component, Prop, Vue } from 'vue-property-decorator'
import ImageRound from '@/components/atoms/ImageRound.vue'

@Component({
  components: {
    ImageRound,
  },
})
export default class StudentList extends Vue {
  private selectedClass = 'selected'

  @Prop()
  students!: [{ [key: string]: any }]

  get classColorSet(): any {
    return function (student: { [key: string]: any }) {
      return { selected: student.selected }
    }
  }

  private changeSelected(student: { [key: string]: any }): void {
    for (let i = 0; i < this.students.length; i++) {
      this.students[i].selected = false
    }
    student.selected = true
    this.$emit('update:students', JSON.parse(JSON.stringify(this.students)))
  }
}
